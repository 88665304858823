import styled from "styled-components";
import { FC, PropsWithChildren, useRef } from "react";

const Wrapper = styled.li<{ isActive: boolean }>`
  padding: 8px;
  cursor: pointer;
  background-color: ${({ theme, isActive }) => isActive ? theme.primary : 'transparent'};
`;

interface Props {
  isActive: boolean;
  onClick: () => void;
}

const RoomName: FC<PropsWithChildren<Props>> = ({
  isActive,
  children,
  onClick,
}) => {
  // const { searchIsCollapsed } = useContext(Context);

  const ref = useRef<HTMLLIElement>(null);

  // useEffect(() => {
  //   // Scroll into view only if search is visible.
  //   // Otherwise, the list will be forced into view regardless
  //   // of whether it should be visible or not.
  //   if(ref.current && isActive && !searchIsCollapsed) {
  //     ref.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'nearest',
  //       inline: 'start'
  //     });
  //   }
  // }, [isActive, ref, searchIsCollapsed]);

  return (
    <Wrapper
      ref={ref}
      isActive={isActive}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  )
}

export default RoomName;
