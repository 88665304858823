import React, { FC } from "react";

const Lights: FC = () => {

  return (
    <>
      <ambientLight />
    </>
  )
}

export default Lights;
