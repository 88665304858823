import { Label } from "../rooms-list/common";
import Trans from "../i18n";
import React from "react";

const PendingSearch = () => {
  return (
    <Label>
      <Trans transKey="enter_search_string_message" />
    </Label>
  )
}

export default PendingSearch;
