import React, { FC, useContext, useEffect, useMemo, useRef } from "react";
import styled from 'styled-components';
import { RoomSelectionContext } from "../app/RoomSelectionProvider";
import Pill from "../theme/Pill";
import rooms from '../navigation/rooms.json';
import Trans from "../i18n";
import { Room } from "../navigation/types";
import ScrollingContainer, { ScrollEvent } from 'react-indiana-drag-scroll';
import { FilterType } from "../rooms-list/types";
import { MobileNavigationContext } from "../app/MobileNavigationProvider";

const Wrapper = styled.div<{ isVisible: boolean }>`
  background: white;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 8px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: flex;
  
  flex-direction: column;
  gap: 20px;
  
  position: relative;
  
  transform: ${({ isVisible }) => isVisible ? 'translate3d(0, 0px, 0)' : 'translate3d(0, 100px, 0)'};
  
  transition: transform 0.3s ease-in-out;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    opacity: min(var(--overflows, 0), var(--progress, 1));
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
  }
  
  &:after {
    right: 0;
  }
  
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md }) {
    justify-content: space-between;
    flex-direction: row;
    
    width: 100%;
    
    .indiana-scroll-container {
      max-width: 60%;
    }
  }

  // Allows interaction with room info panel
  pointer-events: all;
`;

const Tags = styled.div`
  display: flex;
  gap: 10px;

  height: 30px;
  max-width: 60%;
`;


const RoomName = styled.h2`
  margin: 0;
`;

const RoomInfo: FC = () => {
  const { selectedRoomId } = useContext(RoomSelectionContext);
  const scrollingContainerRef = useRef<HTMLElement>(null);
  const wrapperContainer = useRef<HTMLDivElement>(null);

  const roomInfo: Room | undefined = useMemo(() => {
    return rooms._roomList.find((room) => room._id === selectedRoomId);
  }, [selectedRoomId]);

  useEffect(() => {
    if(!scrollingContainerRef.current || !wrapperContainer.current) {
      return;
    }

    const {
      scrollWidth,
      clientWidth
    } = scrollingContainerRef.current;

    const maxScroll = scrollWidth - clientWidth;
    const overflows = maxScroll > 0;
    // @ts-ignore
    wrapperContainer.current.style = `--overflows: ${overflows ? 1 : 0}`;

  }, [scrollingContainerRef, wrapperContainer, selectedRoomId]);

  const onScroll = (e: ScrollEvent) => {
    if(!scrollingContainerRef.current || !wrapperContainer.current) {
      return;
    }

    const {
      scrollLeft,
      scrollWidth,
      clientWidth
    } = scrollingContainerRef.current;

    const maxScroll = scrollWidth - clientWidth;
    const progress = scrollLeft * (100 / maxScroll);

    // @ts-ignore
    // eslint-disable-next-line
    wrapperContainer.current.style = `--progress: ${(100 - progress) / 100}`;
  }

  const {
    addFilter,
  } = useContext(RoomSelectionContext);

  const {
    toggleSearch
  } = useContext(MobileNavigationContext);

  const onAddFilter = (tag: FilterType) => {
    addFilter!(tag);
    toggleSearch!();
  }

  return (
    <Wrapper
      ref={wrapperContainer}
      isVisible={!!roomInfo}
    >
      <RoomName>
        <Trans transKey={`${roomInfo?._id}_name`} />
      </RoomName>
      <ScrollingContainer
        vertical={false}
        onScroll={onScroll}
        innerRef={scrollingContainerRef}
      >
        <Tags>
          {roomInfo?._tags.map((tag) => (
            <Pill key={tag} onClick={() => onAddFilter(tag as FilterType)}>
              <Trans transKey={tag}/>
            </Pill>
          ))}
        </Tags>
      </ScrollingContainer>
    </Wrapper>
  )
}

export default RoomInfo;
